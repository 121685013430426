import React, { useEffect, useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import CustomLink from '../components/Link';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';

const Page404 = (props) => {
  if (!props) return null;

  const footerContent = props.data.allContentfulFooter.edges[0].node;
  const headerContent = props.data.allContentfulHeader.edges[0].node;
  const page404Content = props.data.allContentfulPage404.edges[0].node;

  return (
    <Layout
      themeColor="mode--light"
      currentLocation={props.path}
      currentNodeLocale={page404Content.node_locale}
      footerContent={footerContent}
      headerContent={headerContent}
    >
      <section className="section">
        <div className="container">
          <div className="not-found">
            <ReactMarkdown
              source={page404Content.heading.childMarkdownRemark.rawMarkdownBody}
              disallowedTypes={['paragraph']}
              unwrapDisallowed={true}
            />
            <CustomLink link={page404Content.link} />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const page404Query = graphql`
  query {
    allContentfulHeader {
      edges {
        node {
          node_locale
          enableNotificationBar
          notificationBar {
            json
          }
          logo {
            file {
              url
            }
          }
          navigation {
            linkName
            link
            enableLink
            externalLink
            extraClasses
            badge
          }
          menuPlans {
            linkName
            link
            enableLink
            externalLink
            extraClasses
            badge
          }
          helpline {
            linkName
            link
            enableLink
            externalLink
            extraClasses
            badge
          }
          cookies {
            content {
              json
            }
            essentialSubCategories {
              cookieSubCategory
              cookieValue
              subCategoryDescription {
                childMarkdownRemark {
                  rawMarkdownBody
                }
              }
            }
            optionalSubCategories {
              cookieSubCategory
              cookieValue
              subCategoryDescription {
                childMarkdownRemark {
                  rawMarkdownBody
                }
              }
            }
            buttons {
              linkName
              link
              enableLink
              externalLink
              extraClasses
              badge
            }
          }
        }
      }
    }
    allContentfulPage404 {
      edges {
        node {
          node_locale
          heading {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          link {
            linkName
            link
            enableLink
            externalLink
            extraClasses
          }
        }
      }
    }

    allContentfulFooter {
      edges {
        node {
          mobileFloatingMenu {
            linkName
            link
            enableLink
            externalLink
            extraClasses
          }
          menuPlans {
            linkName
            link
            enableLink
            externalLink
            extraClasses
            badge
          }
          accordion {
            title
            columnPlacement
            accordionEntries {
              linkName
              link
              enableLink
              externalLink
              extraClasses
            }
          }
          footerGallery {
            json
          }
          disclaimer {
            json
          }
          copyright
          social {
            file {
              url
            }
            description
          }
          socialLinks
          madeWithLove
          node_locale
        }
      }
    }
  }
`;

export default Page404;
